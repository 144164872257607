@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700;900&display=swap');
@import '~antd/dist/antd.css';

body {
	margin: 0;
	font-family: 'Roboto', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
}

*,
*::after,
*::before {
	margin: 0;
	padding: 0;
	-webkit-box-sizing: inherit;
	box-sizing: inherit;
}

h1,
h2,
h3 {
	font-weight: 600 !important;
	margin: 0 !important;
	padding: 0 !important;
}

p {
	margin: 0 !important;
	padding: 0 !important;
}

a,
a:visited,
a:hover,
a:active,
a:link {
	text-decoration: none !important;
	/* color: inherit !important; */
}

:root {
	--white: #fff;
	--blue: #007bff;
	--dark-blue: #043d79;
	--purple: #6f42c1;
	--red: #dc3545;
	--yellow: #ffc107;
	--green: #28a745;
	--secondary: #6ab175;
	--primary: #399d39;
	--tertiary: #9dd8a2;

	--black: #1a1a1a;
	--black-light: #484848;
	--dark-grey: #989898;
	--grey: #c0c0c0;
	--dark-grey-light: #999;
	--light-grey: #f2f2f2;
	--step-color-purple: #b299c6;
	--step-color-white: #f0f7f5;
	--step-color-lite-red: #fce0dd;
	--step-color-green: #41b2a8;
	--step-color-red: #f2485b;
	--dark-theme: #10171f;
	--dark-theme-tertiary: #263143;
}

th {
	font-weight: bold !important;
}

.primary-blue {
	color: #0092e0 !important;
}

.spinner {
	height: 100vh;
	width: 100vw;
	background-color: #eeeeee;
}

.spinner-body {
	position: fixed;
	z-index: 10000;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.login-link {
	margin-right: 1rem;
	color: white;
}

.login-link-2 {
	margin-right: 1rem;
	color: #222;
}

.ant-layout-sider-children {
	overflow-y: auto !important;
}

.my-select-container .ant-select .ant-select-selector {
	border-radius: 20px !important;
	/* background-color: #1de4bc10 !important; */
}

.red-bg {
	background-color: hsl(353, 87%, 92%) !important;
}
.green-bg {
	background-color: hsl(98, 29%, 85%) !important;
}
.yellow-bg {
	background-color: hsl(49, 100%, 94%) !important;
}

.machine-template-inputs {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr;
	grid-gap: 1rem;
}

@media only screen and (max-width: 760px) {
	.machine-template-inputs {
		grid-template-columns: 1fr 1fr;
	}
}

@media only screen and (max-width: 450px) {
	.machine-template-inputs {
		grid-template-columns: 1fr;
	}
}

.custom-card-box {
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
	grid-gap: 20px;
}

.custom-card {
	background-color: #f0f0f0;
	padding: 20px;
	border-radius: 8px;
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
	min-width: 200px;
	max-width: 400px;
}

.custom-card.blue {
	border-top: 4px solid #3498db;
}
.custom-card.green {
	border-top: 4px solid #2ecc71;
}
.custom-card.orange {
	border-top: 4px solid #e67e22;
}
.custom-card.red {
	border-top: 4px solid #e74c3c;
}
.custom-card.purple {
	border-top: 4px solid #9b59b6;
}
.custom-card.teal {
	border-top: 4px solid #008080;
}
.custom-card.yellow {
	border-top: 4px solid #f1c40f;
}
.custom-card.pink {
	border-top: 4px solid #e91e63;
}
.custom-card.brown {
	border-top: 4px solid #795548;
}
.custom-card.gray {
	border-top: 4px solid #95a5a6;
}

@media (max-width: 1200px) {
	.custom-card-box {
		grid-template-columns: repeat(auto-fill, minmax(33.33%, 1fr));
	}
}

@media (max-width: 768px) {
	.custom-card-box {
		grid-template-columns: repeat(auto-fill, minmax(50%, 1fr));
	}
}

@media (max-width: 576px) {
	.custom-card-box {
		grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
	}
}
